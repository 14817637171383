import React, { useState, useEffect } from "react";
import { message, Table, PageHeader, Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios, { Axios } from "axios";
import sectionsData from "../data/sezioni-categorie";
import { navigate } from "gatsby";
import NavBar from "../components/navBar";
import { Helmet } from "react-helmet";
import "../styles/main.sass";



function OnlineTable() {
  const [type, setType] = useState(false);
  const [sezione, setSezione] = useState(false);
  const [categoria, setCategoria] = useState(false);
  const [step, setStep] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      render: (text) => (
        <span
          style={{ color: "grey" }}
          dangerouslySetInnerHTML={{ __html: text }}
        ></span>
      ),
    },
    {
      title: "Nome Gruppo",
      dataIndex: "nomeGruppo",
      key: "nomeGruppo",
    },
    {
      title: "Istituto",
      dataIndex: "istituto",
      key: "istituto",
    },
    {
      title: "Voto",
      dataIndex: "voto",
      key: "voto",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.voto - b.voto,
    },
    {
      title: "Premio",
      dataIndex: "premio",
      key: "premio",
    },
    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      render: (text) => (
        <a href={text}>Guarda</a>
      ),
    },
  ];

  const columnsSolisti = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      render: (text) => (
        <span
          style={{ color: "grey" }}
          dangerouslySetInnerHTML={{ __html: text }}
        ></span>
      ),
    },
    {
      title: "Istituto",
      dataIndex: "istituto",
      key: "istituto",
    },
    {
      title: "Voto",
      dataIndex: "voto",
      key: "voto",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.voto - b.voto,
    },
    {
      title: "Premio",
      dataIndex: "premio",
      key: "premio",
    },

  ];

  if (type === 'online') {
    columnsSolisti.push({
      title: "Video",
      dataIndex: "video",
      key: "video",
      render: (text) => (
        <a target="_blank" href={text}>Guarda</a>
      ),
    })
  }

  if (type === 'online') {
    columns.push({
      title: "Video",
      dataIndex: "video",
      key: "video",
      render: (text) => (
        <a target="_blank" href={text}>Guarda</a>
      ),
    })
  }

  function CategoryButton({ index, name, description, scuola }) {
    if (step === 1) {
      return (
        <button
          onClick={() => {
            setSezione(index);
            setStep(2);
          }}
        >
          <span>{name} </span>
          {description}
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            getData(index);
          }}
        >
          <span>{name} </span>
          {description}
        </button>
      );
    }
  }

  function getData(index) {
    console.log(sezione + " " + index + " " + type);
    axios
      .post(
        "https://concorsopaoloferro.edu.it/concorso-api/dashboard/risultati.php",
        {
          sezione: sezione,
          categoria: index,
          tipo: type,
        }
      )
      .then((result) => {
        setLoading(false);
        console.log(result.data);
        setData(result.data);
        setStep(3);
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
        message.error("I risultati non sono ancora pubblici");
        return null;
      });
  }

  return (
    <>
      <Helmet>
        <title>Risultati concorso</title>
        <link
          rel="canonical"
          href="https://concorsopaoloferro.edu.it/canonical-page/"
        />
      </Helmet>
      <NavBar></NavBar>
      <div style={{ height: 100, marginTop: 200 }}></div>
      {step === 0 ? (
        <main>
          <div className="catSelect-w">
            <h2>Seleziona la tipologia</h2>
            <button
              onClick={() => {
                setType("online");
                setStep(1);
              }}
            >
              Risultati concorso online
            </button>
            <button
              onClick={() => {
                setType("offline");
                setStep(1);
              }}
            >
              Risultati concorso in presenza
            </button>
          </div>
        </main>
      ) : step === 1 ? (
        <main>
          <div className="catSelect-w">
            <h2>Seleziona una sezione</h2>
            {sectionsData.map((item, index) => {
              return (
                <CategoryButton
                  index={index}
                  name={item.nome}
                  description={item.descrizione}
                  key={index}
                />
              );
            })}
          </div>
        </main>
      ) : step === 2 ? (
        <main>
          <div className="catSelect-w">
            <h2>Seleziona una sezione</h2>
            {sectionsData[sezione].categorie.map((item, index) => {
              return (
                <CategoryButton
                  index={index}
                  name={item.nome}
                  description={item.descrizione}
                  scuola={item.scuola}
                  key={index}
                />
              );
            })}
          </div>
        </main>
      ) : step === 3 ? (
        <main>
          {sezione > 1 ? (
            <Table columns={columns} dataSource={data} />
          ) : (
            <Table columns={columnsSolisti} dataSource={data} />
          )}
        </main>
      ) : (
        <div>loading</div>
      )}
    </>
  );
}

export default OnlineTable;
